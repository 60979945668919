// lib
import { Module } from 'vuex'

// app
import { TSectionState } from '@/app/article/shared/types'
import { TRootState } from '@/app/shared/types'
import state from './state'
import mutations from './mutations'
import actions from './actions'

export default <Module<TSectionState, TRootState>>{
  namespaced: true,
  state,
  mutations,
  actions
}
