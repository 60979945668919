// lib
import Vue from 'vue'
import Vuex, { ModuleTree } from 'vuex'

// app
import { TRootState } from '@/app/shared/types'

// modules
import article from '@/app/article/shared/store'

Vue.use(Vuex)

export const state = () => ({})

const modules: ModuleTree<TRootState> = {
  article
}

export default new Vuex.Store({
  strict: true,
  state,
  modules
})
