// lib
import { ActionTree } from 'vuex'

// app
import { TSectionState } from '@/app/article/shared/types'
import { TRootState } from '@/app/shared/types'
import Section from '@/app/article/shared/models/Section'
import SectionService from '@/app/article/shared/services/SectionService'

export default <ActionTree<TSectionState, TRootState>> {
  async fetch ({ commit }, id: number) {
    const section = await SectionService.fetch(id)

    commit('update', section)
  },

  async fetchList ({ commit }) {
    const sections = await SectionService.fetchList()

    commit('updateList', sections)
  },

  async create ({ commit }, section: Section) {
    const response = await SectionService.create(section)
    const data = response.data.data

    let key: keyof typeof data
    for (key in data) {
      commit('updateField', { field: key, value: data[key] })

      if (key === 'id') {
        section.id = data[key]
      }
    }
    commit('article/updateField', {
      field: 'parent',
      value: { id: section.id, title: section.title }
    }, { root: true })
    commit('addItem', section)
  },

  async update ({ commit }, section: Section) {
    const response = await SectionService.update(section.toJson())
  }
}
