// app
import Model from '@/app/shared/models/Model'

export interface IArticleAuthor {
  id: number
  avatar: string
  name: string
}

export default class ArticleAuthor extends Model<IArticleAuthor> implements IArticleAuthor {
  id = 1
  avatar = ''
  name = ''

  constructor (props?: IArticleAuthor) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }

}
