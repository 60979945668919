import development from './development'
import production from './production'

const env = {
  development,
  production
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default env[process.env.NODE_ENV]
