// app
import { TArticleState } from '@/app/article/shared/types'
import { PublicationParent } from '@/app/article/shared/models/Publication'
import Article from '@/app/article/shared/models/Article'
import ArticleAuthor from '@/app/article/shared/models/ArticleAuthor'

export default function state (): TArticleState {
  return {
    entity: new Article({
      author: new ArticleAuthor(),
      coverImage: '',
      dateCreated: '',
      dateModified: '',
      datePublished: '',
      isActive: 0,
      isSitemap: 0,
      link: '',
      metaDescription: '',
      metaTitle: '',
      microdata: '',
      parent: new PublicationParent({
        id: 1,
        title: 'Главная секция'
      }),
      subtitle: '',
      title: '',
      typeId: 2
    }),
    list: [],
    pagination: {
      itemsPerPage: 10,
      page: 1,
      totalProducts: 0
    }
  }
}
