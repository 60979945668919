// lib
import { MutationTree } from 'vuex'

// app
import { TSectionState } from '@/app/article/shared/types'
import Section from '@/app/article/shared/models/Section'
import initialState from './state'

export default <MutationTree<TSectionState>>{
  clear (state) {
    state.entity = initialState().entity
  },

  update (state, section: Section) {
    state.entity = section
  },

  updateList (state, list: Section[]) {
    state.list = list
  },

  updateField (state, { field, value }: { field: keyof Section, value: never }) {
    state.entity[field] = value
  },

  addItem (state, item: Section) {
    state.list.push(item)
  }
}
