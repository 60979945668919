// lib
import { GetterTree } from 'vuex'

// app
import { TArticleState } from '@/app/article/shared/types'
import { TRootState } from '@/app/shared/types'

export default <GetterTree<TArticleState, TRootState>>{
  link (state) {
    const arrLink =  state.entity.link.split('/')
    const length = arrLink.length
    const articleLink = arrLink[length - 1]

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return state.section.entity.link + '/' + articleLink
  }
}
