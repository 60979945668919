// app
import AbstractConstructor, { IConstructor } from './index'

export default class ConstructorBlock extends AbstractConstructor {
  content: AbstractConstructor[]

  constructor (data?: IConstructor & { content?: IConstructor[] }) {
    super(data)

    this.content = []
  }

  findById ($uuid: string): AbstractConstructor | null {
    if (this.hasUuidWith($uuid)) {
      return this
    }

    let found: AbstractConstructor | null = null

    this.content.forEach(v => {
      const result = v.findById($uuid)
      if (result) {
        found = result
      }
    })

    return found
  }

  hasContent(): boolean {
    return true
  }

  addItemByIndex (index: number, item: AbstractConstructor): void {
    this.content.splice(index, 0, item)
  }

  updateItemByIndex (index: number, item: AbstractConstructor): void {
    this.content.splice(index, 1, item)
  }

  deleteItemByIndex (index: number): void {
    this.content = this.content.filter((_, i) => i !== index)
  }
}
