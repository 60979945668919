// app
import Model from '@/app/shared/models/Model'

export interface IConstructorItem {
  type: string
  attrs: Record<string, any>
  content?: IConstructorItem[]
}

export default class ConstructorItem extends Model<IConstructorItem> implements IConstructorItem {
  type = ''
  attrs = {}
  content?: ConstructorItem[]

  constructor (props?: IConstructorItem) {
    super(props)

    Object.assign(this, props)

    if (props && props.content) {
      this.content = props?.content?.map(v => new ConstructorItem(v))
    }
  }
}
