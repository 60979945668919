// lib
import { Module } from 'vuex'

// app
import { TArticleState } from '@/app/article/shared/types'
import { TRootState } from '@/app/shared/types'

import modules from './modules'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default <Module<TArticleState, TRootState>>{
  namespaced: true,
  modules,
  state,
  mutations,
  actions,
  getters
}
