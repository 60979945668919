// lib
import { RouteConfig } from 'vue-router'

// app
import DashboardView from '../dashboard-view.vue'

export default <RouteConfig[]>[{
  path: '/',
  alias: '/dashboard',
  name: 'Dashboard',
  component: DashboardView
}]
