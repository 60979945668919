// app
import Publication, { IPublication, PublicationParent } from './Publication'
import ArticleAuthor, { IArticleAuthor } from './ArticleAuthor'
import ConstructorItem, { IConstructorItem } from '@/app/constructor/shared/models/ConstructorItem'
import AbstractConstructor from '@/app/constructor/shared/models'
import ConstructorBlock from '@/app/constructor/shared/models/ConstructorBlock'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'

export interface IArticle extends IPublication {
  typeId?: 2
  author: IArticleAuthor
  subtitle?: string
  coverImage?: string
  content?: IConstructorItem[]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface IArticleOverriden extends IArticle {
  content?: IConstructorItem
}

export default class Article extends Publication implements IArticleOverriden {
  typeId?: 2 = 2
  subtitle?: string
  coverImage?: string
  author!: ArticleAuthor
  content?: ConstructorItem

  constructor (props: IArticle & { content?: IConstructorItem[] }) {
    super(props)

    Object.assign(this, props)

    this.author = new ArticleAuthor(props.author)
    this.parent = new PublicationParent(props.parent)

    if (props.content) {
      const constructorApp = new ConstructorBlock({
        type: 'main',
        attrs: {}
      })
      constructorApp.content = this._makeConstructorContent(props.content)
      this.content = constructorApp
    }
  }

  private _makeConstructorContent (content: IConstructorItem[]): AbstractConstructor[] {
    return content.map(v => {
      // is block template
      if (['article-block-simple'].includes(v.type)) {
        const block = new ConstructorBlock(v)
        block.content = this._makeConstructorContent(v.content || [])

        return block
      }

      return new ConstructorComponent(v)
    })
  }

  toJsonWith (data : {
    content: AbstractConstructor | AbstractConstructor[],
    link: string
  }): any {
    let contentResult = data.content

    if (data.content instanceof ConstructorBlock) {
      contentResult = data.content.content
    }

    const result: any = {
      ...this,
      parent: {
        id: this.parent.id,
      },
      author: this.author.id,
      typeId: undefined,
      dateCreated: undefined,
      dateModified: undefined,
      dateModify: undefined,
      datePublished: undefined,
      content: JSON.stringify(contentResult)
    }

    if (data.link) {
      result.link = data.link
    }

    return result
  }
}
