// lib
import { ModuleTree } from 'vuex'

// app
import { TRootState } from '@/app/shared/types'

// modules
import editor from '@/app/constructor/shared/store'
import section from './section'

export default <ModuleTree<TRootState>>{
  editor,
  section
}
