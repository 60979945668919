// lib
import axios, { AxiosResponse } from 'axios'

// app
import Response from '@/app/shared/models/response'
import Section, { ISection } from '@/app/article/shared/models/Section'

export default class SectionService {
  static async fetch (id: number): Promise<Section> {
    const response: AxiosResponse<Response<ISection>> = await axios.get(`/sections/${id}`)

    return new Section(response.data.data)
  }

  static async fetchList (): Promise<Section[]> {
    const response: AxiosResponse<Response<ISection[]>> = await axios.post('/sections')

    return response.data.data.map(v => new Section(v))
  }

  static create (data: Section): Promise<AxiosResponse<Response<{ createdAt: string, id: number, updatedAt: string }>>> {
    return axios.post('/sections/create', {
      ...data,
      parent: {
        id: data.parent.id
      },
      typeId: undefined
    } as Section)
  }

  static update (data: ISection): Promise<AxiosResponse<Response<any>>> {
    return axios.post('/sections/update', data)
  }
}
