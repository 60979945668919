// app
import AbstractConstructor, { IConstructor } from './index'

export default class ConstructorComponent extends AbstractConstructor {
  constructor (data?: IConstructor) {
    super(data)
  }

  findById ($uuid: string): AbstractConstructor | null {
    if (this.hasUuidWith($uuid)) {
      return this
    }

    return null
  }

  hasContent (): boolean {
    return false
  }
}
