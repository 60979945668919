// lib
import axios from 'axios'

// app
import config from '@/config'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = config.http.baseUrlApi || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

axios.interceptors.request.use(
  function (conf) {
    // Do something before request is sent
    return conf
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error)
  }
)
