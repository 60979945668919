import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

// routers
import dashboardRouter from '../dashboard/shared/router'
import articleRouter from '../article/shared/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...dashboardRouter,
  ...articleRouter
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
