// app
import Publication from './Publication'

export interface ISection extends Publication {
  typeId?: 1
}

export default class Section extends Publication implements ISection {
  typeId?: 1 = 1

  constructor (props: ISection) {
    super(props)

    Object.assign(this, props)
  }

  toJson (): any {
    return {
      id: this.id,
      parent: {
        id: this.parent.id
      },
      title: this.title,
      link: this.link,
      isActive: this.isActive,
      isSitemap: this.isSitemap,
      metaDescription: this.metaDescription,
      metaTitle: this.metaTitle,
      microdata: this.microdata,
    }
  }

}
