// lib
import { Module } from 'vuex'

// app
import { TRootState } from '@/app/shared/types'
import { TConstructorState } from '@/app/constructor/shared/types'
import state from './state'
import mutations from './mutations'

export default <Module<TConstructorState, TRootState>>{
  namespaced: true,
  state,
  mutations
}
