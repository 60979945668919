
// lib
import { Vue, Component } from 'vue-property-decorator'

import '@/assets/styles/index.scss'

@Component({
  components: {
    DefaultLayout: () => import('@/app/layouts/default-layout.vue')
  }
})
export default class App extends Vue {
  get layout (): string {
    return this.$route.meta?.layout || 'DefaultLayout'
  }
}
