// app
import { TConstructorState } from '@/app/constructor/shared/types'
import ConstructorItem from '@/app/constructor/shared/models/ConstructorItem'
import ConstructorBlock from '@/app/constructor/shared/models/ConstructorBlock'

export default function (): TConstructorState {
  return {
    entity: new ConstructorBlock(new ConstructorItem()) // empty article
  }
}
