// common
import common from './common'

export default {
  ...common,

  /**
   * HTTP
   */
  http: {
    baseUrlApi: 'https://admin-article.qashop.diam-almaz.ru/api/v1',
    siteUrlApi: 'https://admin-article.qashop.diam-almaz.ru/api-mobile'
  },
  defaultSiteUrl: 'https://test.diam-almaz.ru',
  defaultJournalUrl: 'https://test.diam-almaz.ru/',
}
