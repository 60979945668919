// app
import { TSectionState } from '@/app/article/shared/types'
import Section from '@/app/article/shared/models/Section'

export default function (): TSectionState {
  return {
    entity: new Section({
      parent: {
        id: null,
        title: 'Общий журнал'
      },
      title: '',
      link: '',
      metaTitle: '',
      metaDescription: '',
      microdata: '',
      isActive: 1,
      isSitemap: 0
    }),
    list: []
  }
}
