// app
import IUniqable from '@/app/shared/models/IUniqable'
import { generateUniqueId } from '@/app/shared/utils/unique'

export default class Model<T> implements IUniqable {
  $uuid?: string

  constructor (data?: T & { $uuid?: string }) {
    let uniqueId = data?.$uuid ?? generateUniqueId()

    Object.defineProperty(this, '$uuid', {
      enumerable: false,
      get: () => uniqueId,
      set: ($uuid: string) => uniqueId = $uuid
    })
  }
}
