// app
import Model from '@/app/shared/models/Model'

export interface IConstructor {
  $uuid?: string
  type: string
  attrs: Record<string, any>
}

export default abstract class AbstractConstructor extends Model<IConstructor> implements IConstructor {
  type = ''
  attrs: Record<string, any> = {}

  constructor (props?: IConstructor) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }

  hasUuidWith ($uuid: string): boolean {
    return this.$uuid === $uuid
  }

  abstract findById ($uuid: string): AbstractConstructor | null

  abstract hasContent (): boolean
}
