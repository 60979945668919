// lib
import { MutationTree } from 'vuex'

// app
import { TConstructorState } from '@/app/constructor/shared/types'
import initialState from './state'
import AbstractConstructor from '@/app/constructor/shared/models'
import ConstructorBlock from '@/app/constructor/shared/models/ConstructorBlock'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'
import ConstructorFile from '@/app/constructor/shared/models/ConstructorFile'

export default <MutationTree<TConstructorState>>{
  clear (state) {
    state.entity = initialState().entity
  },

  update (state, item: ConstructorBlock) {
    state.entity = item
  },

  updateItem (state, item: AbstractConstructor) {
    const foundItem = state.entity.findById(item.$uuid ?? '')
    console.warn('foundItem', foundItem, state.entity)

    if (!foundItem) {
      return
    }

    foundItem.attrs = item.attrs
    foundItem.type = item.type

    if (item instanceof ConstructorBlock && foundItem instanceof ConstructorBlock) {
      foundItem.content = item.content
    }
  },

  addItem (state, data: { $uuid: string, index: number, file: ConstructorFile }) {
    const foundItem = state.entity.findById(data.$uuid)

    if (foundItem instanceof ConstructorBlock) {
      foundItem.addItemByIndex(
        data.index,
        data.file.type === 'block'
          ? new ConstructorBlock({
            type: data.file.name,
            attrs: {},
            content: []
          })
          : new ConstructorComponent({
            type: data.file.name,
            attrs: {}
          })
      )
    }
  },

  deleteItem (state, data: { $uuid: string, index: number }) {
    const foundItem = state.entity.findById(data.$uuid)

    if (foundItem instanceof ConstructorBlock) {
      foundItem.deleteItemByIndex(data.index)
    }
  }
}
